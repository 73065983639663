import { useEffect, useState } from 'react'
import { Button, Card, Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import { BsFileEarmarkPdfFill, BsSearch } from 'react-icons/bs'
import '../../../css/masterDetail.css'
import '../../../css/generalStyles.css'
import DSHeineken from '../Services/Heineken.Services'
import FileManagerDS from '../../../Services/Utils/FileManager.Services'
import DataTable from 'react-data-table-component'
import { MsgInformativo } from '../../Utils/Toast/msgInformativo'
import DTOProgCruce from '../DTO/DTOProgCruce'
import { IconContext } from 'react-icons'

export interface IRptProgramacionCrucesProps {}

export default function RptProgramacionCruces(
  props: IRptProgramacionCrucesProps
) {
  const [Data, setData] = useState<DTOProgCruce[]>([])
  const [DataOriginal, setDataOriginal] = useState<DTOProgCruce[]>([])
  const [Inicio, setInicio] = useState(currentDate(0))
  const [Fin, setFin] = useState(currentDate(0))
  const [filtro, setFiltro] = useState('')
  const [show, setShowMsg] = useState(false)
  const [header, setHeader] = useState('')
  const [msg, setMsg] = useState('')
  const [msgColor, setMsgColor] = useState('primary')

  const colData = [
    {
      name: 'id',
      width: '100px',
      selector: (row: DTOProgCruce) => row.id,
      sortable: true,
    },
    {
      name: 'Prefile',
      center: true,
      width: '80px',
      cell: (row: DTOProgCruce) => (
        <OverlayTrigger
          placement='top'
          overlay={ row.fechaPrefile ? <Tooltip id="button-tooltip-2">{row.fechaPrefile}</Tooltip> : <br style={{display:'none'}}/>}
        >
        <div
          onClick={() => {
            GetPrefile(row.factura)
          }}
          style={{ cursor: 'pointer' }}
        >
          <IconContext.Provider value={{ color: 'red', size: '17px' }}>
            <BsFileEarmarkPdfFill />
          </IconContext.Provider>
        </div>
        </OverlayTrigger>
      ),
    },
    {
      name: 'Doda',
      center: true,
      width: '80px',
      cell: (row: DTOProgCruce) => (
        <OverlayTrigger
          placement='top'
          overlay={ row.fechaDoda ? <Tooltip id="button-tooltip-2">{row.fechaDoda}</Tooltip> : <br style={{display:'none'}}/>}
        >
          <div
            onClick={() => {
              GetDoda(row.id)
            }}
            style={{ cursor: 'pointer' }}
          >
            <IconContext.Provider value={{ color: 'red', size: '17px' }}>
              <BsFileEarmarkPdfFill />
            </IconContext.Provider>
          </div>
        </OverlayTrigger>
      ),
    },
    {
      name: 'BL',
      center: true,
      width: '60px',
      cell: (row: DTOProgCruce) => (
        <div
          onClick={() => {
            ShowBL2PDF(row.id)
          }}
          style={{ cursor: 'pointer' }}
        >
          <IconContext.Provider value={{ color: 'red', size: '17px' }}>
            <BsFileEarmarkPdfFill />
          </IconContext.Provider>
        </div>
      ),
    },
    {
      name: 'GPS',
      selector: (row: DTOProgCruce) => row.gps,
      sortable: true,
    },
    {
      name: 'Folio Carta Porte',
      width: '300px',
      selector: (row: DTOProgCruce) => row.folioCartaPorte,
      sortable: true,
    },
    {
      name: 'Transportista',
      width: '300px',
      selector: (row: DTOProgCruce) => row.transportista,
      sortable: true,
    },
    {
      name: 'Proveedor',
      width: '200px',
      selector: (row: DTOProgCruce) => row.heineken,
      sortable: true,
    },
    {
      name: 'Pedido',
      selector: (row: DTOProgCruce) => row.poCliente,
      sortable: true,
    },
    {
      name: 'Pedido SAP',
      width: '120px',
      selector: (row: DTOProgCruce) => row.pedidoSAP,
      sortable: true,
    },
    {
      name: 'Shipment',
      selector: (row: DTOProgCruce) => row.shipment,
      sortable: true,
    },
    {
      name: 'Destino',
      width: '250px',
      selector: (row: DTOProgCruce) => row.destino,
      sortable: true,
    },
    {
      name: 'Fecha',
      width: '100px',
      selector: (row: DTOProgCruce) => row.fecha.substring(0, 10),
      sortable: true,
    },
    {
      name: 'Factura',
      selector: (row: DTOProgCruce) => row.factura,
      sortable: true,
    },
    {
      name: 'Aduana',
      selector: (row: DTOProgCruce) => row.aduana,
      sortable: true,
    },
    {
      name: 'Patente',
      selector: (row: DTOProgCruce) => row.patente,
      sortable: true,
    },
    {
      name: 'Caja',
      width: '180px',
      selector: (row: DTOProgCruce) => row.caja,
      sortable: true,
    },
    {
      name: 'Bultos',
      selector: (row: DTOProgCruce) => row.bultos,
      sortable: true,
    },
    /* {
      name: 'Fecha envio',
      selector: (row: DTOProgCruce) => row.fechaEnvio,
      sortable: true,
    },
    {
      name: 'Fecha prefile',
      selector: (row: DTOProgCruce) => row.fechaPrefile,
      sortable: true,
    },
    {
      name: 'Hora prefile',
      selector: (row: DTOProgCruce) => row.horaPrefile,
      sortable: true,
    }, */
    {
      name: 'Urgente',
      selector: (row: DTOProgCruce) => row.urgente,
      sortable: true,
    },
    {
      name: 'Estatus',
      selector: (row: DTOProgCruce) => row.estatus,
      sortable: true,
    },
    {
      name: 'Fecha impr.',
      selector: (row: DTOProgCruce) => row.fechaImpresion,
      sortable: true,
    },
    {
      name: 'Dias trans',
      right: true,
      selector: (row: DTOProgCruce) => row.diasTranscurridos,
      sortable: true,
    },
  ]

  const ShowBL2PDF = (id: number) => {
    const newWindow = window.open(
      process.env.REACT_APP_SERVICES +
        'heineken/services/BOL2PDF' +
        '?id=' +
        id,
      '_blank',
      'noopener,noreferrer'
    )
    if (newWindow) newWindow.opener = null
  }

  const GetPrefile = (Prefile: string) => {
    FileManagerDS.getPrefile(Prefile)
      .then((response: any) => {
        if (response.status === 200) {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const url = window.URL.createObjectURL(blob)
          window.open(url)
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Prefile no existe')
        setShowMsg(true)
        return
      })
  }

  function currentDate(days: number): string {
    var today = new Date()
    today.setDate(today.getDate() + days)
    var dd = String(today.getDate()).padStart(2, '0')
    var mm = String(today.getMonth() + 1).padStart(2, '0')
    var yyyy = today.getFullYear()
    return yyyy + '-' + mm + '-' + dd
  }

  const loadReport = (Inicio: string, Fin: string) => {
    DSHeineken.GetProgramacionCruces()
      .then((response) => {
        console.log(response.data)
        setData(response.data)
        setDataOriginal(response.data)
      })
      .catch((e: Error) => {
        alert('Ocurrio un error' + e.message.toString())
      })
  }

  useEffect(() => {
    loadReport(currentDate(0), currentDate(0))
  }, [])

  const filtraReporte = (e: any) => {
    setFiltro(e.target.value)
    if (e.target.value.length > 0) {
      setData(
        Data.filter(function (row) {
          return (
            row.fecha
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) ||
            row.factura
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) ||
            row.poCliente
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) ||
            row.heineken
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) ||
            row.destino
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) ||
            row.pedidoSAP
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) ||
            row.caja.toLowerCase().includes(e.target.value.toLocaleLowerCase())
          )
        })
      )
    } else {
      setData(DataOriginal)
    }
  }

  const GetDoda = (id: number) => {
    FileManagerDS.getFileContent(id, 34)
    .then((response: any) => {
      if (response.status === 200) {
        const blob = new Blob([response.data], { type: 'application/pdf' })
        const url = window.URL.createObjectURL(blob)
        window.open(url)
      }
    })
    .catch((e: Error) => {
      setHeader('Error')
      setMsg('Doda no existe')
      setShowMsg(true)
      return
    })
  }

  return (
    <div>
      <Row style={{ paddingTop: '10px' }}>
        <Col xs={12}></Col>
      </Row>
      <Card>
        <Card.Body>
          <Row>
            <Col xs={3}>
              <Card.Title>Programacion de cruces</Card.Title>
            </Col>
            <Col xs={3}></Col>
            <Col xs={2}>
              <Button
                variant='primary'
                size='sm'
                onClick={() => {
                  loadReport(Inicio, Fin)
                }}
              >
                <BsSearch />
                Actualizar
              </Button>
            </Col>
            <Col xs={4}>
              <Form.Control
                type='text'
                size='sm'
                placeholder='Search...'
                onChange={(e) => {
                  filtraReporte(e)
                }}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Row style={{ paddingTop: '10px' }}>
        <Col xs={12}>
          <DataTable
            noHeader
            defaultSortFieldId={'id'}
            defaultSortAsc={true}
            pagination
            paginationPerPage={15}
            striped={true}
            dense={true}
            highlightOnHover
            columns={colData}
            data={Data.filter(function (row) {
              return row
            })}
          />
        </Col>
      </Row>
      <Card>
        <Card.Body></Card.Body>
      </Card>
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        time={2000}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </div>
  )
}
