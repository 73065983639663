import http from '../../../Services/common/http-common'
import DTOProgCruce from '../DTO/DTOProgCruce'
import DTORptCruces from '../DTO/DTORptCruces'
import DTORptFacturas from '../DTO/DTORptFacturas'
import IFacturasHeader from '../Interfaces/IFacturasHeader'

class CHeinekenDataService {
  GetFacturasTxt(Inicio: string, Fin: string) {
    return http.get<IFacturasHeader[]>(
      `/Reportes/RptFacturasTxt?Inicio=${Inicio}&Fin=${Fin}`
    )
  }
  GetProgramacionCruces() {
    return http.get<DTOProgCruce[]>(`/Reportes/RptProgramacionCruces`)
  }
  GetFacturas(Inicio: string, Fin: string) {
    return http.get<DTORptFacturas[]>(
      `/Reportes/RptFacturas?Inicio=${Inicio}&Fin=${Fin}`
    )
  }

  GetCruces(Inicio: string, Fin: string) {
    return http.get<DTORptCruces[]>(
      `/Reportes/RptCruces?Inicio=${Inicio}&Fin=${Fin}`
    )
  }

  GetBL(id: number) {
    http.defaults.baseURL = process.env.REACT_APP_SERVICES
    return http
      .get<ArrayBuffer>(`heineken/services/BOL2PDF?id=${id}`, {
        responseType: 'arraybuffer',
      })
      .then(function (response) {
        return response
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  AppendFolioCartaPorte(id: number, FolioCartaPorte: string) {
    return http.put<DTOProgCruce>(
      `/Reportes/AppendFolioCartaPorte/${id}`,
      FolioCartaPorte
    )
  }

  AppendShipmentsXLSX(formData: FormData){
    return http.post(`/Reportes/AppendShipmentsXLSX`,formData,{
        headers:{
            "Content-Type":'multipart/form-data'
        }
    })
  }
}
export default new CHeinekenDataService()
