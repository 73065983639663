import http from '../common/http-common'
import IRespuesta from '../../Interfaces/IRespuesta'
import IFileManager from '../../Interfaces/Utils/IFileManager'

class FileManagerDataService {
  getFileContent(id: number, Proceso: number) {
    return http
      .get<ArrayBuffer>(`/FileManager/getFile?id=${id}&proceso=${Proceso}`, {
        responseType: 'arraybuffer',
      })
      .then(function (response) {
        return response
      })
      .catch(function (error) {
        console.log(error)
      })
  }
  getFileInfoFromProcess(id: number, proc: number) {
    return http.get<IFileManager>(
      `/FileManager/GetFileInfoByProcess?id=${id}&Proceso=${proc}`
    )
  }
  getFileInfoById(id: number) {
    return http.get<IFileManager>(`/FileManager/GetFileInfoById?id=${id}`)
  }
  DeleteFile(id: number) {
    return http.delete<IRespuesta>(`/FileManager/DeleteById/${id}`)
  }
  getPrefile(Prefile: string) {
    return http
      .get<ArrayBuffer>(`/FileManager/GetPrefile?Prefile=${Prefile}`, {
        responseType: 'arraybuffer',
      })
      .then(function (response) {
        return response
      })
      .catch(function (error) {
        console.log(error)
      })
  }
  AppendPrefiles(data: FormData, UserId: number){
    return http.post(`FileManager/AppendPrefiles?IdUsuario=${UserId}`, data, {
      headers:{
        "Content-Type":'multipart/form-data'
      }
    })
  }
  AppendDodas(data: FormData, UserId: number){
    return http.post(`FileManager/AppendDodas?IdUsuario=${UserId}`, data, {
      headers:{
        "Content-Type":'multipart/form-data'
      }
    })
  }
}
export default new FileManagerDataService()
