import React, { useEffect, useState } from 'react'
import ItemMenu from './Interfaces/Catalogos/IItemMenu'
import { Outlet, useNavigate } from 'react-router-dom'
import { logued } from './store/features/userStatusSlice/userStatusSlice'
import { Container, Form, Nav, Navbar, NavDropdown } from 'react-bootstrap'
import usuariosServices from './Services/Catalogos/Usuarios.Services'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from './store/store'
import { IconContext } from 'react-icons'
import { BsPersonCircle } from 'react-icons/bs'
import logo from './images/largeLogo.png'
import './App.css'
import Submenu from './Components/Submenu/Submenu'

function App() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  let _menu: ItemMenu[] = []
  const [mainMenu, setMainMenu] = useState(_menu)
  const userLogued = useSelector((state: RootState) => state.userStatus.value)
  const [show, setShow] = useState(false)
  const [msg, setMsg] = useState('')
  const [header, setHeader] = useState('')
 // const msgColor = 'primary'
  const menu = localStorage.getItem('menu') ? localStorage.getItem('menu') : ''
  const User = () => {
    const stickyValue = window.localStorage.getItem('User')
    return stickyValue !== null ? JSON.parse(stickyValue) : ''
  }
  const [Depto, setDepto] = useState(() => {
    const stickyValue = window.localStorage.getItem('Departamento')
    return stickyValue !== null
      ? JSON.parse(stickyValue).substring(0, 4) + '.'
      : ''
  })

  useEffect(() => {
    if (menu && userLogued) {
      setMainMenu(JSON.parse(menu))
    } else {
      setMainMenu([])
    }
  }, [menu, userLogued])

  useEffect(() => {
    navigate('/')
  }, [])

  useEffect(() => {
    usuariosServices
      .validate()
      .then((response) => {
        if (response.status === 200) {
          dispatch(logued(true))
        }
      })
      .catch((e: Error) => {
        if (!window.location.href.indexOf('login')) {
          localStorage.setItem('menu', '')
          localStorage.setItem('token', '')
          setHeader('Informativo')
          setMsg('Su session ha expirado, necesita volver a loguerse')
          setShow(true)
          navigate('/')
        }
      })
  }, [dispatch, navigate])

  return (
    <div className='App'>
      <Navbar className='navBarColor'
        expand='lg'>
        <Container>
          <Navbar.Brand style={{ color: '#FFFFFF' }}>
            <img
              onClick={()=>navigate('/')}
              src={logo}
              alt='Heineken'
              style={{ width: '70%', cursor:'pointer' }}
            ></img>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='me-auto'>
              {mainMenu
                ? mainMenu.map((itemMenu, index) => {
                    if (itemMenu.padreId === 0) {
                      return (
                        <>
                          <Submenu
                            descripcion={itemMenu.descripcion}
                            key={itemMenu.id}
                            allItems={mainMenu}
                            submenu={mainMenu.filter(function (item) {
                              return item.padreId === itemMenu.id
                            })}
                          />
                        </>
                      )
                    }
                  })
                : ''}
            </Nav>
            <Nav>
              <Form className='d-flex' style={{ color: '#ffffff' }}>
                {!userLogued ? (
                  <>
                    <Nav.Link style={{ color: '#ffffff' }} href='/#/login'>
                      Login
                    </Nav.Link>
                  </>
                ) : (
                  <>
                    {/* <Nav.Link  style={{ color: '#ffffff' }} href='/#/help'>Help</Nav.Link> */}
                    <Nav.Link style={{ color: '#ffffff' }} href='/#/logout'>
                      Logout
                    </Nav.Link>
                    <div
                      style={{ paddingTop: '5px', paddingLeft: '20px' }}
                    ></div>
                    <IconContext.Provider value={{ size: '25px' }}>
                      <BsPersonCircle />
                    </IconContext.Provider>
                    <div style={{ paddingTop: '5px', paddingLeft: '5px' }}>
                      {Depto}
                    </div>
                  </>
                )}
              </Form>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Outlet />
    </div>
  )
}

export default App
