import React from 'react'
import ReactDOM from 'react-dom/client'
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store/store'
import { HashRouter, Route, Routes } from 'react-router-dom'
import Login from './Components/Login/Login'
import Dashboard from './Components/Dashboard/Dashboard'
import Logout from './Components/Logout/logout'
import Register from './Components/Register/register'
import ForgotPassword from './Components/Login/ForgotPassword'
import PIN from './Components/Login/PIN'
import UserControl from './Components/UserControl/UserControl'
import ResetCredentials from './Components/Login/ResetCredentials'
import RptFacturasTxt from './Components/Cliente/Reportes/RptFacturasTxt'
import CatTransportistas from './Components/Cliente/Catalogos/CatTransportistas'
import RptProgramacionCruces from './Components/Cliente/Reportes/RptProgramacionCruces'
import RptFacturas from './Components/Cliente/Reportes/RptFacturas'
import RptProgramacionCrucesT from './Components/Cliente/Reportes/RptProgramacionCrucesT'
import RptCruces from './Components/Cliente/Reportes/RptCruces'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const PageNotFound = () => {
  return (
    <div style={{ textAlign: 'center', paddingTop: '300px' }}>
      <h2>Sorry, no matching page</h2>
    </div>
  )
}

root.render(
  <Provider store={store}>
    <React.StrictMode>
      <HashRouter>
        <Routes>
          <Route path='/' element={<App />}>
            <Route path='/' element={<Dashboard />} />
            <Route path='/login' element={<Login />} />
            <Route path='/forgotPassword' element={<ForgotPassword />} />
            <Route path='/PIN' element={<PIN />} />
            <Route path='/logout' element={<Logout />} />
            <Route path='/Register' element={<Register />} />
            <Route path='/UserControl' element={<UserControl />} />
            <Route path='/Reset' element={<ResetCredentials />} />
            <Route path='/RptFacturasTxt' element={<RptFacturasTxt />} />
            <Route
              path='/RptProgramacionCruces'
              element={<RptProgramacionCruces />}
            />
            <Route
              path='/RptProgramacionCrucesT'
              element={<RptProgramacionCrucesT />}
            />
            <Route path='/RptFacturas' element={<RptFacturas />} />
            <Route path='/CatTransportistas' element={<CatTransportistas />} />
            <Route path='/RptContenedoresCruzados' element={<RptCruces/>}/>
            <Route path='*' element={<PageNotFound />} />
          </Route>
        </Routes>
      </HashRouter>
    </React.StrictMode>
  </Provider>
)

reportWebVitals()
