import { useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import { AdminDashboard } from './AdminDashboard'
import { ClientesDashboard } from './ClientesDashboard'
import { TransportistasDashboard } from './TransportistasDashboard'


interface IProps {}

export default function Dashboard(props: IProps) {
  const [Depto, setDepto] = useState(() => {
    const stickyValue = window.localStorage.getItem('Departamento')
    return stickyValue !== null ? JSON.parse(stickyValue) : 0
  })
  const [Perfil, setPerfil] = useState(() => {
    const stickyValue = window.localStorage.getItem('Perfil')
    return stickyValue !== null ? JSON.parse(stickyValue) : 0
  })
  const [User, setUser] = useState(() => {
    const stickyValue = window.localStorage.getItem('User')
    return stickyValue !== null ? JSON.parse(stickyValue) : 0
  })

  return (
    <div>
      <br />
      <Container>
        <Row xs={1} md={3} className='g-4'>
          {Perfil === 'Administrador' ? 
            <AdminDashboard/> : ''
          }
          {Perfil === 'CLIENTE: HEINEKEN' ? 
            <ClientesDashboard/> : ''
          }
          {Perfil === 'Transportista' ? 
            <TransportistasDashboard/> : ''
          }
        </Row>
      </Container>
    </div>
  )
}
