import React, { useEffect, useState } from 'react'
import { DashboardItem } from '../Utils/DashboardItem/DashboardItem'
import { FcCalendar, FcDocument, FcShipped } from 'react-icons/fc'
import { AxiosError } from 'axios';
import DashboardService from '../../Services/Dashboard/Dashboard.Service';

export const ClientesDashboard = () => {
  const [Facturas, setFacturas] = useState('0');
  const [Programaciones, setProgramaciones] = useState('0');
  const [Cruces, setCruces] = useState('0');
  const [FechaCruces, setFechaCruces] = useState(currentDate(0))

  function currentDate(days: number): string {
      var today = new Date()
      today.setDate(today.getDate() + days)
      var dd = String(today.getDate()).padStart(2, '0')
      var mm = String(today.getMonth() + 1).padStart(2, '0')
      var yyyy = today.getFullYear()
      return yyyy + '-' + mm + '-' + dd
  }

  useEffect( () => {
      LoadEverything()
  }, [])

  async function LoadEverything() {
      await getFacturas()
      await getProgramaciones()
      await getCruces(FechaCruces)
  }

  const getFacturas = async () => {
    DashboardService.getFacturas()
    .then((resp) => {
        setFacturas(resp.data)
    })
    .catch((err:AxiosError) => {
        console.log(err.message)
    })
}

const getProgramaciones = async () => {
    DashboardService.getProgramaciones()
    .then((resp) => {
        setProgramaciones(resp.data)
    })
    .catch((err:AxiosError) => {
        console.log(err.message)
    })
}

  const getCruces = async (fecha: string) => {
      DashboardService.getCruces(fecha)
      .then((resp) => {
          setCruces(resp.data)
      })
      .catch((err:AxiosError) => {
          console.log(err.message)
      })
  }


  return (
    <>
        <DashboardItem cardHeight={'400px'} cardWidth={'85%'} cardHeader={'Facturas'} iconColor={''} iconSize={'8rem'} Icon={FcDocument} data={Facturas} route={'RptFacturas'}/>
        <DashboardItem cardHeight={'400px'} cardWidth={'85%'} cardHeader={'Programación de Cruces'} iconColor={''} iconSize={'8rem'} Icon={FcCalendar} data={Programaciones} route={'RptProgramacionCruces'}/>
        <DashboardItem cardHeight={'400px'} cardWidth={'85%'} cardHeader={'Contenedores Cruzados'} iconColor={''} iconSize={'8rem'} Icon={FcShipped} data={Cruces} route={'RptContenedoresCruzados'}
            date={FechaCruces} 
            onChange={(date: string) => { getCruces(date) }} 
        />
    </>
  )
}
